html, body, div, span, applet, object, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	box-sizing: border-box; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block; }

ol, ul, li {
	list-style: none; }

blockquote, q {
	quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
	content: ''; }

table {
	border-collapse: collapse;
	border-spacing: 0; }

th, td {
	vertical-align: middle; }
/* custom */
a {
	outline: none;
	color: #333333;
	text-decoration: none;
	-webkit-backface-visibility: hidden; }
a,a:hover,a:active,a:visited,a:link,a:focus {
	-webkit-tap-highlight-color: transparent;
	outline: none;
	background: none;
	text-decoration: none; }

input:focus, select:focus, textarea:focus {
	outline: -webkit-focus-ring-color auto 0; }

button {
	border: 0 none;
	background: #5B88ED;
	border-radius: 5px;
	outline: none; }
/*取消button点击的默认样式*/
button:focus {
	outline: 0; }

//  给swiper-container定义个100%高
.swiper-container {
	height: 100%; }


body {
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box; }
* {
	box-sizing: border-box; }
// 页面公共
.container {
	max-width: 1190px;
	margin: 0 auto;
	margin-top: 134px;
	margin-bottom: 20px;
	position: relative;
	display: flex;
	justify-content: space-between;
	overflow-x: hidden;
	min-height: 50vh; }
