footer.footer {
	@include flex_cc();
	flex-wrap: wrap;
	width: 100%;
	background: #6C6C6C;
	padding: 20px;
	padding-top: 0;
	p,a {
		@include font(#fff,16,20);
		margin-right: 15px;
		text-decoration: none; }
	p {
		margin-top: 20px; } }
