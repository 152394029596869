// 钛媒体视频投稿
.pc_auth_index {
	background: #F4F5FA;
	.container {
		max-width: 1440px;
		margin: 0 auto;
		margin-top: 120px;
		margin-bottom: 20px;
		position: relative; }
	.auth_info_box {
		background: #ffffff;
		margin: 0 auto;
		// margin-top: 20px
		// margin-bottom: 20px
		padding-top: 50px;
		padding-bottom: 76px;
		width: 1190px;
		min-width: 1190px;
		box-shadow: 0px 2px 3px 0px rgba(219, 220, 225, 0.42);
		display: flex;
		align-items: center;
		flex-direction: column;
		.tit {
			@include font(#333,22,27);
			text-align: center;
			position: relative;
			z-index: 2;
 }			// font-weight: bold
		.desc {
			@include font(#999999,16,20);
			margin-top: 10px;
			margin-bottom: 30px;
			text-align: center;
			position: relative;
			z-index: 2; }
		.auth_btn_ligent {
			background: linear-gradient(224deg, #2EC8B9 0%, #3EEDF4 100%);
			padding: 12px 18px;
			border-radius: 8px;
			display: inline-block;
			@include font(#fff,14,20);
			cursor: pointer;
			position: relative;
			z-index: 2;
			font-weight: bold;
			box-shadow: 0px 2px 8px 0px rgba(47, 203, 189, 0.43);
			text-shadow: 0px 1px 1px rgba(25, 139, 130, 0.33); }
		.auth_img {
			width: 775px;
			margin-top: -100px;
			img {
				width: 100%;
				height: 100%; } }
		.box_top {
			display: flex;
			align-items: center;
			flex-direction: column; }
		.box_bottom {
			margin-top: 90px;
			.info {
				margin-top: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				.line {
					width: 8px;
					height: 13px;
					display: inline-block;
					margin: -30px 40px 0;
					background: url('/public/imgs/auth_video/index/icon_line.png');
					background-size: 100% 100%; }
				.item {
					display: flex;
					flex-direction: column;
					align-items: center;
					img {
						width: 72px;
						height: 72px;
						min-height: 72px;
						min-width: 72px; }
					p {
						@include font(#33384E,14,17);
						margin-top: 25px; } } } } } }
// 用户认证信息审核
.pc_auth_detail {
	background: #F4F5FA;
	.container {
		max-width: 1440px;
		margin: 0 auto;
		margin-top: 120px;
		margin-bottom: 20px;
		position: relative; }
	.auth_info_box {
		background: #ffffff;
		margin: 0 auto;
		// margin-top: 20px
		// margin-bottom: 20px
		padding-top: 30px;
		padding-bottom: 40px;
		width: 1190px;
		min-width: 1190px;
		box-shadow: 0px 2px 3px 0px rgba(219, 220, 225, 0.42);
		display: flex;
		align-items: center;
		flex-direction: column;
		.agreement_part {
			display: flex;
			flex-direction: column;
			align-items: center;
			.tit {
				@include font(#333,18,27);
				text-align: center;
				position: relative;
				z-index: 2;
				margin-top: 30px;
 }				// font-weight: bold
			.desc {
				@include font(#999999,14,20);
				margin-top: 12px;
				margin-bottom: 30px;
				text-align: center;
				position: relative;
				z-index: 2; }
			.inner {
				width: 929px;
				border: 1px solid #E8E8E8;
				height: 367px;
				padding: 20px;
				@include font(#666,14,25);
				overflow-y: scroll;
				margin-bottom: 37px; }
			.auth_btn_ligent {
				margin-top: 20px;
				background: #fff;
				padding: 12px 18px;
				border-radius: 8px;
				display: inline-block;
				@include font($main_color,14,20);
				cursor: pointer;
				position: relative;
				z-index: 2;
				font-weight: bold;
				border: 1px solid $main_color;
				transition: all .5s;
				&:hover {
					transition: all .5s;
					cursor: pointer;
					background: linear-gradient(224deg, #2EC8B9 0%, #3EEDF4 100%);
					@include font(#fff,14,20);
					border: 1px solid #fff; } }
			.check_btn {
				@include font(#666,16,20);
				display: inline-block;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 1;
				cursor: pointer;
				.img {
					display: inline-block;
					width: 17px;
					height: 17px;
					min-height: 17px;
					min-width: 17px;
					margin-right: 10px;
					background: url('/public/imgs/auth_video/upload/icon_check_grey.png');
					background-size: 100% 100%; }
				&:hover {
					.img {
						background: url('/public/imgs/auth_video/upload/icon_check_lv.png');
						background-size: 100% 100%; } } }
			.check_btn_current {
				@include font(#666,16,20);
				display: inline-block;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 1;
				cursor: pointer;
				.img {
					display: inline-block;
					width: 17px;
					height: 17px;
					min-height: 17px;
					min-width: 17px;
					margin-right: 10px;
					background: url('/public/imgs/auth_video/upload/icon_check_y.png');
					background-size: 100% 100%; } } }
		.write_part,.review_part {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 126px;
			width: 100%;
			.tit {
				@include font(#333,18,27);
				text-align: center;
				position: relative;
				z-index: 2;
				margin-top: 30px;
				font-weight: bold; }
			.desc {
				@include font(#999999,14,20);
				margin-top: 12px;
				margin-bottom: 30px;
				text-align: center;
				position: relative;
				z-index: 2; }
			.auth_btn_ligent {
				background: linear-gradient(224deg, #2EC8B9 0%, #3EEDF4 100%);
				padding: 12px 18px;
				border-radius: 8px;
				display: inline-block;
				@include font(#fff,14,20);
				cursor: pointer;
				position: relative;
				z-index: 2;
				font-weight: bold;
				text-shadow: 0px 1px 1px rgba(25, 139, 130, 0.33); }
			.auth_form {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
				>div {
					margin-bottom: 20px;
					width: 100%; }
				h3 {
					@include font(#333,16,20);
					margin-bottom: 20px;
					font-weight: bold;
					&:before {
						content: '*';
						@include font(#FF4C4C,16,20); } }
				.tit_div {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					h3 {
						margin-bottom: 0px;
						margin-right: 20px; } }
				.tips {
					@include font(#999999,14,17); }
				input[type=text] {
					width: 100%;
					border: 1px solid #E8E8E8;
					padding: 13px 20px;
					@include font(#33384E,14,17);
					transition: all .5s;
					&:focus {
						border: 1px solid $main_color;
						transition: all .5s; } }
				.item_page {
					input[type=text] {
						width: 890px;
						@include font($main_color,14,17); }
					.list_item {
						display: flex;
						align-items: center; }
					.add_btn {
						display: inline-block;
						width: 30px;
						height: 30px;
						min-height: 30px;
						min-width: 30px;
						margin-left: 20px;
						cursor: pointer;
						background: url('/public/imgs/auth_video/detail/icon_add.png');
						background-size: 100% 100%;
						transition: all .5s;
						&:hover {
							transition: all .5s;
							background: url('/public/imgs/auth_video/detail/icon_add_hover.png');
							background-size: 100% 100%; } }
					.cut_btn {
						display: inline-block;
						width: 30px;
						height: 30px;
						min-height: 30px;
						min-width: 30px;
						margin-left: 20px;
						cursor: pointer;
						background: url('/public/imgs/auth_video/detail/icon_cut.png');
						background-size: 100% 100%;
						transition: all .5s;
						&:hover {
							transition: all .5s;
							background: url('/public/imgs/auth_video/detail/icon_cut_hover.png');
							background-size: 100% 100%; } } }
				#location_select {
					position: relative;
					select {
						position: relative;
						/*清除select的边框样式*/
						border: 1px solid #E8E8E8;
						/*清除select聚焦时候的边框颜色*/
						outline: none;
						/*将select的宽高等于div的宽高*/
						width: 216px;
						height: 45px;
						line-height: 45px;
						/*隐藏select的下拉图标*/
						appearance: none;
						-webkit-appearance: none;
						-moz-appearance: none;
						/*通过padding-left的值让文字居中*/
						padding-left: 20px;
						margin-right: 26px;
						background: url(/public/imgs/auth_video/upload/icon_select.png) no-repeat scroll right 5px center transparent; } }
				.item_id_pic {
					#upload_pic_id {
						width: 200px;
						height: 140px;
						margin-bottom: 20px;
						position: relative;
						cursor: pointer;
						input {
							position: absolute;
							top: 0;
							display: none; }
						img {
							width: 100%;
							height: 100%; } } }
				.item_confirmation {
					.inner {
						width: 100%;
						background: #FAFAFA;
						border-radius: 5px;
						border: 1px solid #EDEDED;
						padding: 20px;
						>div {
							@include font(#666,14,33);
							margin-bottom: 20px; } }
					.check_btn {
						@include font(#666,14,20);
						display: inline-block;
						display: flex;
						justify-content: center;
						align-items: center;
						line-height: 1;
						cursor: pointer;
						.img {
							display: inline-block;
							width: 16px;
							height: 16px;
							min-height: 16px;
							min-width: 16px;
							margin-right: 10px;
							background: url('/public/imgs/auth_video/detail/icon_check_grey.png');
							background-size: 100% 100%; }
						&:hover {
							.img {
								background: url('/public/imgs/auth_video/detail/icon_check_lv.png');
								background-size: 100% 100%; } } }
					.check_btn_current {
						@include font(#666,14,20);
						display: inline-block;
						display: flex;
						justify-content: center;
						align-items: center;
						line-height: 1;
						cursor: pointer;
						.img {
							display: inline-block;
							width: 16px;
							height: 16px;
							min-height: 16px;
							min-width: 16px;
							margin-right: 10px;
							background: url('/public/imgs/auth_video/detail/icon_check_y.png');
							background-size: 100% 100%; } } } } }
		.review_part {
			display: flex;
			flex-direction: column;
			align-items: center;
			min-height: 574px;
			.reason_txt {
				background: #FFF9ED;
				border-radius: 3px;
				width: 100%;
				padding: 20px;
				padding-left: 45px;
				margin-bottom: 30px;
				@include font(#F38031,14,17);
				position: relative;
				&:before {
					content: '';
					display: inline-block;
					position: absolute;
					top: 50%;
					left: 20px;
					transform: translateY(-50%);
					width: 15px;
					height: 15px;
					min-height: 15px;
					min-width: 15px;
					margin-right: 10px;
					cursor: pointer;
					background: url('/public/imgs/auth_video/detail/icon_tips.png');
					background-size: 100% 100%;
					transition: all .5s; } }
			.tit {
				@include font(#F38031,18,21);
				margin-bottom: 12px;
				margin-top: 30px; }
			.desc {
				@include font(#333,14,17); } }
		// 进度
		.part_progress {
			display: flex;
			justify-content: center;
			align-items: center;
			.line {
				width: 110px;
				height: 1px;
				background: #D3D3D3;
				display: inline-block;
				margin-top: -32px; }
			.line_v {
				width: 110px;
				height: 1px;
				background: linear-gradient(224deg, #2EC8B9 0%, #3EEDF4 100%);
				display: inline-block;
				margin-top: -32px; }
			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				img {
					width: 32px;
					height: 32px;
					min-height: 32px;
					min-width: 32px; }
				p {
					@include font(#33384E,16,20);
					margin-top: 15px; }
				.current {
					font-weight: bold; } } }
		.hide {
			display: none !important; } } }

//认证信息侧边导航
.auth_left_nav {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 190px;
	min-width: 190px;
	max-width: 190px;
	margin-right: 20px;
	.upload_btn {
		width: 100%;
		height: 58px;
		display: inline-block;
		background: url('/public/imgs/auth_video/upload/icon_upload_btn.png');
		background-size: 100% 100%; }

	.ul {
		width: 100%;
		background: #fff;
		padding: 28px 10px;
		margin-top: 20px;
		li {
			width: 100%;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include font(#333,16,20);
			margin-bottom: 10px; }
		.video_li {
			background: none;
			&:before {
				content: '';
				display: inline-block;
				width: 20px;
				height: 18px;
				margin-right: 10px;
				background: url('/public/imgs/auth_video/list/icon_video_grey.png');
				background-size: 100% 100%; }
			&:hover {
				background: #FFF4F0;
				&:before {
					background: url('/public/imgs/auth_video/list/icon_video_v.png');
					background-size: 100% 100%; } }
			&.current {
				background: #FFF4F0;
				&:before {
					background: url('/public/imgs/auth_video/list/icon_video_v.png');
					background-size: 100% 100%; } } }
		li:last-child {
			margin-bottom: 0; } } }
//认证信息资料展示
.pc_auth_show {
	background: #F4F5FA;
	.container {
		max-width: 1190px;
		margin: 0 auto;
		margin-top: 120px;
		margin-bottom: 20px;
		position: relative;
		display: flex;
		justify-content: space-between; }
	.auth_show_box {
		background: #ffffff;
		margin: 0 auto;
		// margin-top: 20px
		// margin-bottom: 20px
		width: 980px;
		min-width: 980px;
		box-shadow: 0px 2px 3px 0px rgba(219, 220, 225, 0.42);
		display: flex;
		align-items: center;
		flex-direction: column;
		.auth_form {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 40px;
			padding-top: 0;
			>div {
				margin-bottom: 20px;
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				padding: 30px 0 20px;
				border-bottom: 1px solid #E6E6E6;
				h3 {
					@include font(#33384E,16,20);
					max-width: 140px;
					width: 140px;
					margin-right: 40px;
					font-weight: bold; }
				p.txt {
					@include font(#33384E,14,20); }
				img.txt {
					width: 180px;
					height: 120px; }
				span.txt {
					@include font($main_color,14,20);
					cursor: pointer; }
				.check_btn_current {
					@include font(#666,16,20);
					display: inline-block;
					display: flex;
					justify-content: center;
					align-items: center;
					line-height: 1;
					.img {
						display: inline-block;
						width: 16px;
						height: 16px;
						min-height: 16px;
						min-width: 16px;
						margin-right: 10px;
						background: url(/public/imgs/auth_video/list/icon_s.png);
						background-size: 100% 100%; } } }
			.item_page {
				p.txt {
					@include font($main_color,14,20); } } } } }
//认证信息视频管理
#upload_video_list {
	background: #F4F5FA;
	.container {
		max-width: 1190px;
		margin: 0 auto;
		margin-top: 120px;
		margin-bottom: 20px;
		position: relative;
		display: flex;
		justify-content: space-between;
		min-height: 100vh; }
	.auth_video_list_box {
		background: #ffffff;
		margin: 0 auto;
		// margin-top: 20px
		// margin-bottom: 20px
		// padding-bottom: 40px
		width: 980px;
		min-width: 980px;
		box-shadow: 0px 2px 3px 0px rgba(219, 220, 225, 0.42);
		display: flex;
		align-items: center;
		flex-direction: column;
		.nav_ul {
			@include flex_sc();
			width: 100%;
			justify-content: flex-start;
			position: relative;
			bottom: -3px;
			border-bottom: 1px solid #F0F0F0;
			padding: 0 40px;
			li {
				@include font(#666666,18,20);
				padding: 20px 0;
				margin-right: 32px;
				cursor: pointer;
				position: relative;
				&:after {
					content: '';
					display: inline-block;
					width: 0%;
					height: 3px;
					background: #666666;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					transition: width 1s; }
				&.current {
					color: #33384E;
					font-weight: bold;
					&:after {
						width: 100%;
						transition: width .5s; } }
				&:hover {
					color: #33384E;
					font-weight: bold;
					&:after {
						width: 100%;
						transition: width .5s; } } } }
		.content_ul {
			width: 100%;
			display: flex;
			flex-direction: column;
			li {
				width: 100%;
				padding: 30px 40px;
				display: flex;
				transition: background .5s;
				border-bottom: 1px solid #F0F0F0;
				&:hover {
					background: #FAFAFA;
					transition: background .5s; }
				.img_box {
					width: 250px;
					height: 140px;
					min-width: 250px;
					margin-right: 20px;
					position: relative;
					img {
						width: 100%;
						height: 100%; }
					.video_time {
						position: absolute;
						bottom: 10px;
						right: 10px;
						@include font(#fff,14,17);
						padding: 4px 14px;
						border-radius: 4px;
						background: rgba(0,0,0,.39); } }
				.info_box {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					width: 100%;
					.content {
						display: flex;
						justify-content: space-between;
						align-items: flex-start;
						.content_top {
							width: 80%;
							.h3 {
								@include font(#33384E,20,25);
								max-width: 600px;
								word-wrap: break-word;
								@include text_2(); }
							>p.time {
								@include font(#666666,16,17);
								margin-top: 20px; } }
						.label {
							border-radius: 4px;
							padding: 3px 10px;
							display: inline-block;
							@include font(#F38031,14,17); } }
					.status {
						@include flex_sc();
						width: 100%;
						.price {
							display: inline-block;
							@include font(#F38031,14,17); }
						.btn_box {
							@include font(#666666,14,17);
							cursor: pointer;
							line-height: 1; }
						.edit_btn {
							transition: all .5s;
							&:before {
								content: '';
								display: inline-block;
								width: 12px;
								height: 12px;
								min-height: 12px;
								min-width: 12px;
								margin-right: 10px;
								cursor: pointer;
								background: url(/public/imgs/auth_video/list/icon_edit_grey.png);
								background-size: 100% 100%;
								transition: all .5s; }
							&:hover {
								color: $main_color;
								transition: all .5s;
								&:before {
									background: url('/public/imgs/auth_video/list/icon_edit.png');
									background-size: 100% 100%; } } }
						.detele_btn {
							transition: all .5s;
							&:before {
								content: '';
								display: inline-block;
								width: 13px;
								height: 12px;
								min-height: 12px;
								min-width: 13px;
								margin-right: 10px;
								cursor: pointer;
								background: url(/public/imgs/auth_video/list/icon_detele_grey.png);
								background-size: 100% 100%;
								transition: all .5s; }
							&:hover {
								color: $main_color;
								transition: all .5s;
								&:before {
									background: url('/public/imgs/auth_video/list/icon_detele.png');
									background-size: 100% 100%; } } }
						.released_btn {
							transition: all .5s;
							&:before {
								content: '';
								display: inline-block;
								width: 12px;
								height: 12px;
								min-height: 12px;
								min-width: 12px;
								margin-right: 10px;
								cursor: pointer;
								background: url(/public/imgs/auth_video/list/icon_published_grey.png);
								background-size: 100% 100%;
								transition: all .5s; }
							&:hover {
								color: $main_color;
								transition: all .5s;
								&:before {
									background: url('/public/imgs/auth_video/list/icon_published.png');
									background-size: 100% 100%; } } } }
					.btn_list {
						display: flex;
						>div {
							margin-left: 15px; } } } }
			.item_review,.item_pending,.item_released {
				.info_box {
					.label {
						background: #FDF2EA;
						color: #F38031 !important; } } }
			.item_published {
				.info_box {
					.label {
						background: #EAF9FF;
						color: #3ABABB !important; } } }
			.item_failed {
				flex-direction: column;
				.li_top {
					display: flex; }
				.status_tips_refuse {
					width: 100%;
					padding: 15px 20px;
					background: #F5F5F7;
					border-radius: 3px;
					display: flex;
					align-items: center;
					margin-top: 20px;
					@include font(#33384E,14,22);
					word-break: break-all;
					&:before {
						content: '';
						display: inline-block;
						width: 15px;
						height: 15px;
						min-width: 15px;
						margin-right: 10px;
						background: url(/public/imgs/auth_video/upload/icon_tips_red.png);
						background-size: 100% 100%; } }
				.info_box {
					.label {
						background: #FFE6E6;
						color: #FF4C4C !important; } } }
			.item_draft {
				.info_box {
					.label {
						background: #EDEDED;
						color: #666666 !important; } } } } } }
// 视频管理
#upload_video_detail {
	.upload_video_box {
		.status_tips {
			width: 100%;
			padding: 20px;
			background: #F5F5F7;
			border-radius: 3px;
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			@include font(#33384E,14,22);
			word-break: break-all;
			&:before {
				content: '';
				display: inline-block;
				width: 15px;
				height: 15px;
				min-width: 15px;
				margin-right: 10px;
				background: url(/public/imgs/auth_video/list/icon_tips.png);
				background-size: 100% 100%; } }
		.form_list {
			opacity: .5; } } }
// 视频上传
#upload_video,.pc_upload_video,#upload_video_detail {
	background: #F4F5FA;
	.container {
		max-width: 1190px;
		margin: 0 auto;
		margin-top: 120px;
		margin-bottom: 20px;
		position: relative;
		display: flex;
		min-height: 100vh;
		justify-content: space-between; }
	.upload_video_box {
		background: #ffffff;
		margin: 0 auto;
		// margin-top: 20px
		// margin-bottom: 20px
		padding-bottom: 40px;
		width: 980px;
		min-width: 980px;
		box-shadow: 0px 2px 3px 0px rgba(219, 220, 225, 0.42);
		display: flex;
		align-items: center;
		flex-direction: column;
		.upload_index {
			padding: 24px 40px;
			.title {
				@include flex_sc();
				>.tit {
					@include font(#33384E,20,24);
					font-weight: bold; }
				>.tips {
					@include font(#FF4C4C,14,17);
					&:before {
						content: '';
						display: inline-block;
						width: 15px;
						height: 15px;
						margin-right: 10px;
						background: url(/public/imgs/auth_video/upload/icon_tips_red.png);
						background-size: 100% 100%;
						position: relative;
						top: 3px; } } }
			#upload_video_file {
				width: 900px;
				height: 400px;
				margin-top: 20px;
				background: url(/public/imgs/auth_video/upload/bg_upload.png);
				background-size: 100% 100%;
				cursor: pointer; }
			.tips_inner {
				margin-top: 20px;
				margin-bottom: 30px;
				width: 900px;
				@include flex_sc();
				.item {
					width: 286px;
					height: 166px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					justify-content: flex-start;
					padding: 24px 20px;
					background: #F5F5F7;
					border-radius: 4px;
					.tit {
						@include font(#33384E,18,21);
						font-weight: bold; }
					.desc {
						@include font(#666666,16,20);
						margin-top: 14px; }
					span {
						@include font($main_color,16,20);
						cursor: pointer; } } }
			.check_btn {
				@include font(#666,16,20);
				display: inline-block;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 1;
				span {
					cursor: pointer;
					@include font($main_color,16,20); }
				.img {
					display: inline-block;
					width: 17px;
					height: 17px;
					min-height: 17px;
					min-width: 17px;
					margin-right: 10px;
					background: url('/public/imgs/auth_video/list/icon_check_grey.png');
					background-size: 100% 100%; }
				&:hover {
					.img {
						background: url('/public/imgs/auth_video/list/icon_check_lv.png');
						background-size: 100% 100%; } } }
			.check_btn_current {
				@include font(#666,16,20);
				display: inline-block;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 1;
				span {
					cursor: pointer;
					@include font($main_color,16,20); }
				.img {
					display: inline-block;
					width: 17px;
					height: 17px;
					min-height: 17px;
					min-width: 17px;
					margin-right: 10px;
					background: url('/public/imgs/auth_video/list/icon_check_y.png');
					background-size: 100% 100%; } } }
		.upload_detail {
			padding: 40px;
			width: 100%;
			.btn_list {
				display: flex;
				margin-top: 10px;
				.auth_btn_ligent {
					background: url('/public/imgs/auth_video/upload/btn_publish.png');
					background-size: 100% 100%;
					width: 92px;
					height: 41px;
					border-radius: 8px;
					display: inline-block;
					@include font(#fff,14,20);
					cursor: pointer;
					position: relative;
					z-index: 2;
					margin-right: 50px;
					font-weight: bold;
					text-shadow: 0px 1px 1px rgba(25, 139, 130, 0.33);
					transition: all .5s;
					&:hover {
						background: url('/public/imgs/auth_video/upload/btn_publish_h.png');
						background-size: 100% 100%;
						transition: all .5s; } }
				.auth_btn_ligent_cancel {
					background: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 92px;
					height: 41px;
					border-radius: 8px;
					@include font(#33384E,14,20);
					cursor: pointer;
					position: relative;
					z-index: 2;
					font-weight: bold;
					border: 1px solid #D6D6D6;
					transition: all .5s;
					&:hover {
						transition: all .5s;
						@include font($main_color,14,20);
						border: 1px solid $main_color; } } }
			.form_list {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				width: 100%;
				.item {
					display: flex;
					width: 100%;
					margin-bottom: 20px;
					flex-direction: column;
					p,h4,li,.upload_other_btn {
						-moz-user-select: none;
						-webkit-user-select: none;
						-ms-user-select: none; }
					.item_name {
						@include flex_sc();
						margin-bottom: 20px;
						h4 {
							@include font(#33384E,16,20);
							font-weight: bold; }
						.h4_must {
							&:before {
								content: '*';
								@include font(#FF4C4C,16,20); } }
						.tips_txt {
							@include font(#FF4C4C,14,17); }
						.tips {
							@include font(#666,12,15);
							margin-left: 14px; } }
					.item_input {
						width: 100%;
						position: relative;
						.sort_select {
							position: relative;
							/*清除select的边框样式*/
							border: 1px solid #E8E8E8;
							/*清除select聚焦时候的边框颜色*/
							outline: none;
							/*将select的宽高等于div的宽高*/
							width: 216px;
							height: 45px;
							line-height: 45px;
							/*隐藏select的下拉图标*/
							appearance: none;
							-webkit-appearance: none;
							-moz-appearance: none;
							/*通过padding-left的值让文字居中*/
							padding-left: 20px;
							margin-right: 26px;
							@include font(#33384E,14,20);
							background: url(/public/imgs/auth_video/upload/icon_select.png) no-repeat scroll right 5px center transparent; }
						>input,textarea {
							width: 100%;
							border: 1px solid #E8E8E8;
							padding: 13px 20px;
							resize: none;
							@include font(#33384E,14,20); }
						.font_num {
							@include font(#666,14,20);
							position: absolute;
							bottom: 14px;
							right: 20px; }
						.tag_list {
							display: flex;
							justify-content: flex-start;
							margin-top: 20px;
							.h4 {
								min-width: 70px;
								@include font(#33384E,14,20); }
							.ul_height {
								height: 126px;
								max-height: 126px; }
							.ul {
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								justify-content: flex-start;
								transition: all 1s;
								overflow: hidden;
								&.open_ul {
									height: auto;
									max-height: unset;
									transition: all 1s; }
								li {
									border: 1px solid #fff;
									background: $main_color;
									border-radius: 4px;
									padding: 5px 10px;
									margin-right: 10px;
									margin-bottom: 10px;
									@include font(#fff,12,20);
									transition: all .5s;
									display: inline-block;
									&:hover {
										border-color: #fff; }
									&.selected {
										color: #999;
										background: #EAECEF;
										pointer-events: none; } } }
							.click_open_btn {
								width: 60px;
								min-width: 60px;
								display: flex;
								align-items: flex-end;
								margin-bottom: 15px;
								@include font(#33384E,14,20);
								cursor: pointer;
								// justify-content: flex-end
								&.open_btn {
									&:after {
										content: '';
										display: inline-block;
										width: 11px;
										height: 6px;
										min-height: 6px;
										min-width: 11px;
										margin-right: 10px;
										background: url('/public/imgs/auth_video/upload/icon_open.png');
										background-size: 100% 100%;
										transition: all .5s;
										margin-bottom: 7px;
										margin-left: 3px; }
									&:hover {
										&:after {
											animation: rotateIn;
											animation-duration: 1s; } } }
								&.reduce_btn {
									&:after {
										content: '';
										display: inline-block;
										width: 11px;
										height: 6px;
										min-height: 6px;
										min-width: 11px;
										margin-right: 10px;
										background: url('/public/imgs/auth_video/upload/icon_up.png');
										background-size: 100% 100%;
										transition: all .5s;
										margin-bottom: 7px;
										margin-left: 3px; }
									&:hover {
										&:after {
											animation: rotateIn;
											animation-duration: 1s; } } } } }
						.input_list {
							.tmt_tags_input {
								border: 1px solid #E8E8E8;
								padding: 7px 10px;
								// padding-bottom: 0
								text-align: left;
								font-size: 0;
								.tagsinput {
									// display: none
									border: none;
									background: unset;
									padding: unset;
									width: 100%;
									min-height: auto !important;
									height: auto !important;
									span.tag {
										// border: 1px solid #fff
										border-color: $main_color;
										background: $main_color;
										border-radius: 4px;
										padding: 5px 10px;
										margin-right: 10px;
										margin-bottom: 10px;
										@include font(#FFFFFF,12,20);
										transition: all .5s;
										display: inline-block;
										cursor: pointer;
										a {
											color: #FFFFFF;
											display: inline-block;
											background: url(/public/imgs/auth_video/upload/icon_close.png);
											background-size: 100% 100%;
											width: 12px;
											height: 12px;
											font-size: 0; }
										// 	&:hover
										// 		animation: backInRight
										// 		animation-duration: 1s
										// // &:hover
										// // 	animation: backInRight
 }										// // 	animation-duration: 1s
									input#tagsinputval_tag {
										@include font(#999,14,17);
										color: #999 !important;
										border: none;
										box-shadow: none;
										outline: none;
										padding: 0;
										margin: 0;
										width: auto !important;
										max-width: inherit;
										min-width: 160px;
										vertical-align: top;
										// margin-bottom: 7px
										height: 31px;
										padding-left: 5px; } }
								.badge {
									border-radius: 5px;
									background-color: $main_color;
									@include font(#fff,12,15);
									cursor: pointer;
									display: inline-block;
									position: relative;
									vertical-align: middle;
									overflow: hidden;
									margin-right: 10px;
									padding: 8px 28px 8px 14px;
									transition: .25s linear;
									margin-bottom: 7px;
									>span {
										color: white;
										padding: 0 10px 0 0;
										cursor: pointer;
										font-size: 12px;
										position: absolute;
										right: 0;
										text-align: right;
										text-decoration: none;
										top: 0;
										width: 100%;
										bottom: 0;
										z-index: 2;
										&:after {
											content: "";
											display: inline-block;
											width: 11px;
											height: 11px;
											min-height: 11px;
											min-width: 11px;
											background: url(/public/imgs/auth_video/upload/icon_close.png);
											background-size: 100% 100%;
											-webkit-font-smoothing: antialiased;
											-moz-osx-font-smoothing: grayscale;
											line-height: 27px;
											position: absolute;
											top: 50%;
											transform: translateY(-50%);
											right: 5px; } } } }
							@media (hover:hover) {
								.tmt_tags_input {
									.badge {
										padding: 8px 21px;
										>span {
											opacity: 0;
											filter: "alpha(opacity=0)";
											transition: opacity .25s linear; }
										&:hover {
											background-color: #2EC8B9;
											color: white;
											padding-right: 28px;
											padding-left: 14px;
											>span {
												padding: 0 10px 0 0;
												opacity: 1;
												-webkit-filter: none;
												filter: none; } } } } } }
						.check_list {
							display: flex;
							align-items: center;
							li {
								@include font(#33384E,14,20);
								margin-right: 20px;
								width: 82px;
								display: flex;
								align-items: center;
								transition: all .5s;
								cursor: pointer;
								&:before {
									content: '';
									display: inline-block;
									width: 16px;
									height: 16px;
									min-height: 16px;
									min-width: 16px;
									margin-right: 10px;
									background: url('/public/imgs/auth_video/upload/icon_check.png');
									background-size: 100% 100%;
									transition: all .5s;
									margin-top: -2px; }
								&:hover {
									color: $main_color;
									transition: all .5s;
									&:before {
										background: url('/public/imgs/auth_video/upload/icon_check_h.png');
										background-size: 100% 100%;
										transition: all .5s; } }
								&.checked {
									color: $main_color;
									&:before {
										background: url('/public/imgs/auth_video/upload/icon_check_y.png');
										background-size: 100% 100%; } } } } } }

				#video_label {
					.item_name {
						justify-content: flex-start; } }
				#video_name {
					input {
						height: 45px; }
					.font_num {
						bottom: 50%;
						transform: translateY(50%); } }
				#video_desc {
					textarea {
						height: 124px; } }
				#video_publish {
					.item_input {
						display: flex;
						align-items: center;
						#select_time {
							display: flex;
							align-items: center;
							input {
								border-radius: 4px;
								border: 1px solid #D9D9D9;
								cursor: pointer;
								padding: 8px 10px;
								// padding-right: 50px
								margin-right: 20px;
								position: relative;
								@include font(#33384E,14,17);
								width: 200px;
								&:hover {
									border: 1px solid $main_color; } }
							.tmt_tmd {
								position: relative;
								cursor: pointer;
								&:before {
									content: '';
									display: inline-block;
									width: 11px;
									height: 10px;
									min-height: 10px;
									min-width: 10px;
									margin-right: 20px;
									background: url('/public/imgs/auth_video/upload/icon_ymd.png');
									background-size: 100% 100%;
									transition: all .5s;
									position: absolute;
									top: 50%;
									right: 10px;
									transform: translateY(-50%);
									z-index: 1; } }
							.tmt_hms {
								position: relative;
								cursor: pointer;
								&:before {
									content: '';
									display: inline-block;
									width: 11px;
									height: 11px;
									min-height: 11px;
									min-width: 11px;
									margin-right: 20px;
									background: url('/public/imgs/auth_video/upload/icon_hms.png');
									background-size: 100% 100%;
									transition: all .5s;
									position: absolute;
									top: 50%;
									right: 10px;
									transform: translateY(-50%);
									z-index: 1; } } } } }
				#video_img {
					.item_input {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						.upload_video_img {
							width: 212px;
							height: 121px;
							background: url('/public/imgs/auth_video/upload/bg_upload_img.png');
							background-size: 100% 100%;
							margin-right: 35px;
							cursor: pointer;
							position: relative;
							.result_img {
								position: absolute;
								width: 212px;
								height: 121px;
								top: 0;
								left: 0; }
							#upload_video_img {
								opacity: 0;
								cursor: pointer;
								width: 100%;
								height: 100%; }
							.replace_img_btn {
								opacity: 1;
								position: absolute;
								bottom: 10px;
								right: 5px;
								width: 80px;
								height: 30px;
								background: rgba(0,0,0,.6);
								z-index: 2;
								border-radius: 6px;
								@include font(#fff,14,17);
								display: flex;
								justify-content: center;
								align-items: center; } }
						.video_img_list {
							width: 660px;
							height: 145px;
							display: flex;
							flex-direction: column;
							background: url('/public/imgs/auth_video/upload/bg_line.png');
							background-size: 100% 100%;
							padding: 14px 37px 24px 48px;
							.tips {
								@include font(#999,12,17);
								margin-bottom: 10px; }
							.ul {
								display: flex;
								align-items: center;
								justify-content: space-between;
								li {
									width: 130px;
									height: 73px;
									background: url('/public/imgs/auth_video/upload/bg_upload_img2.png');
									background-size: 100% 100%;
									img {
										width: 100%;
										height: 100%; } } }
							.can_check {
								li {
									border: 1px solid #fff;
									transition: all .5s;
									cursor: pointer;
									&:hover {
										border: 1px solid $main_color;
										transition: all .5s; }
									&.current {
										position: relative;
										border: 1px solid $main_color;
										&:before {
											content: '';
											position: absolute;
											bottom: 0;
											right: 0;
											width: 12px;
											height: 13px;
											z-index: 3;
											background: url(/public/imgs/auth_video/upload/icon_checked.png);
											background-size: 100% 100%; } } } } } } }
				#upload_video_file {
					box-shadow: 0px 2px 4px 0px rgba(245, 245, 245, 0.34);
					border-radius: 6px;
					border: 1px solid #F5F5F5;
					padding: 15px 20px;
					width: 100%;
					.title {
						@include font(#33384E,20,24);
						display: flex;
						align-items: center;
						width: 100%;
						font-weight: bold;
						&:before {
							content: '';
							display: inline-block;
							width: 20px;
							height: 20px;
							min-height: 20px;
							min-width: 20px;
							margin-right: 10px;
							background: url('/public/imgs/auth_video/upload/icon_name_v.png');
							background-size: 100% 100%;
							transition: all .5s; } }
					.progress_box {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.progress_list {
							margin-top: 17px;
							margin-bottom: 10px;
							width: 780px;
							.m-percent {
								display: none; } }
						.btn_list {
							.upload_other_btn {
								@include font(#33384E,14,17);
								cursor: pointer;
								transition: all .5s;
								&:hover {
									color: $main_color;
									transition: all .5s; } }
							.cancel_upload {
								display: flex;
								align-items: center;
								&:before {
									content: '';
									display: inline-block;
									width: 13px;
									height: 13px;
									min-height: 13px;
									min-width: 13px;
									margin-right: 5px;
									background: url('/public/imgs/auth_video/upload/icon_cancle.png');
									background-size: 100% 100%;
									transition: all .5s; } } } }
					.info_list {
						display: flex;
						align-items:  center;
						justify-content: flex-start;
						>p {
							@include font(#666666,14,17);
							margin-right: 30px; }
						.progress_rate {
							margin-left: 150px; } } }
				#video_free {
					.input_flex {
						display: flex;
						align-items: center; }
					.price_a {
						@include font(#666666,12,15);
						a {
							@include font($main_color,12,15); } }
					#select_price {
						display: flex;
						align-items: center;
						@include font(#33384E,14,15);
						margin-top: 20px;
						input {
							border-radius: 4px;
							border: 1px solid #D9D9D9;
							cursor: pointer;
							padding: 8px 10px;
							// padding-right: 50px
							margin-right: 20px;
							position: relative;
							@include font(#33384E,14,17);
							width: 200px;
							&:hover {
								border: 1px solid $main_color; } } } } } } }
	.hide {
		display: none !important; } }
[data-url] {
	cursor: pointer; }
// 弹窗
.tmt_layer_tips {
	.layui-layer-title {
		background: #fff;
		margin: 0 30px;
		text-align: center;
		padding: 0; }
	.layui-layer-content {
		@include font(#666666,14,22); }
	.layui-layer-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		.layui-layer-btn0 {
			background: $main_color;
			border-radius: 20px;
			@include font(#fff,14,22);
			height: auto;
			padding: 2px 30px;
			border-color: $main_color; }
		.layui-layer-btn1 {
			padding: 2px 30px;
			background: #fff;
			border-radius: 20px;
			@include font(#666,14,22);
			border: 1px solid #D4D4D4;
			height: auto; } } }
.tmt_tips_red {
	background: #FFEFE6 !important;
	border-radius: 5px !important;
	overflow: hidden;
	.layui-layer-title {
		display: none; }
	.layui-layer-content {
		background: #FFEFE6;
		border-radius: 5px;
		border: 1px solid #FFD3BB;
		padding: 13px 20px;
		@include font(#FF4C4C,14,22);
		display: flex;
		align-items: center;
		&:before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			min-width: 15px;
			margin-right: 10px;
			background: url(/public/img/common/tips_red.png);
			background-size: 100% 100%; } } }
.tmt_tips_green {
	background: #BBF2D0 !important;
	border-radius: 5px !important;
	overflow: hidden;
	.layui-layer-title {
		display: none; }
	.layui-layer-content {
		background: #BBF2D0;
		border-radius: 5px;
		border: 1px solid #BBF2D0;
		padding: 13px 20px;
		@include font(#19BF6C,14,22);
		display: flex;
		align-items: center;
		&:before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			min-width: 15px;
			margin-right: 10px;
			background: url(/public/img/common/tips_green.png);
			background-size: 100% 100%; } } }
.tmt_tips_yellow {
	background: #FFE89E !important;
	border-radius: 5px !important;
	overflow: hidden;
	.layui-layer-title {
		display: none; }
	.layui-layer-content {
		background: #FFE89E;
		border-radius: 5px;
		border: 1px solid #FFE89E;
		padding: 13px 20px;
		@include font(#19BF6C,14,22);
		display: flex;
		align-items: center;
		&:before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			min-width: 15px;
			margin-right: 10px;
			background: url(/public/img/common/tips_yellow.png);
			background-size: 100% 100%; } } }

body#upload_video,body#upload_video_list,body#upload_video_detail {
	display: flex;
	flex-direction: column; }













