header.header {
	padding: 18px;
	background: #fff;
	box-shadow: 0px 6px 20px 0px rgba(219,220,225,0.42);
	@include flex_sc();
	width: 100%;
	position: fixed;
	z-index: 111111;
	.header_container {
		width: 1440px;
		@include flex_sc();
		margin: 0 auto;
		height: 100%;
		padding: 0 130px;
		.dsp_logo {
			width: 132px;
			img {
				width: 100%; } }
		.is_login {
			position: relative;
			.username {
				@include flex_cc();
				@include font(#666666,16,14);
				cursor: pointer;
				img {
					width: 20px;
					height: 20px;
					min-width: 20px;
					margin-right: 10px;
					border-radius: 50%; } }
			.unlogin_btn {
				width: 143px;
				height: 74px;
				background: url(/public/imgs/common/icon_unlogin.png);
				background-size: 100% 100%;
				position: absolute;
				top: 45px;
				right: 0;
				z-index: 100; } } }
	.hide {
		display: none !important; } }
