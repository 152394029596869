// 加载更多按钮
#load_more,.load_more {
	font-size: 14px;
	color: #ccc;
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	&.load_over {
		.loading {
			display: none; } }
	.loading {
		display: inline-block;
		height: 20px;
		width: 20px;
		border-radius: 100%;
		margin: 10px;
		border: 2px solid #ccc;
		border-bottom-color: transparent;
		vertical-align: middle;
		-webkit-animation: rotate 1.1s infinite linear;
		animation: rotate 1.1s infinite linear; } }
// 错误提示
.form_msg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	text-align: center;
	ul {
		list-style-type: none; }
	li,p {
		max-width: 70%;
		display: inline-block;
		padding: 12px 20px 10px;
		color: #594042;
		font-size: 13px;
		line-height: 1em;
		background-color: #FFF2F2;
		border: 1px solid #FFDBDB; } }
// 加载效果旋转
@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }
@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }


// 跳转
[data-js-url],[data-cursor] {
	cursor: pointer; }

// code下拉选择框
.sel_area {
	position: relative;
	cursor: pointer;
	.show_code {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; }
	.country_code_menu {
		padding: 10px;
		top: 14px;
		left: -94px;
		display: none;
		position: absolute;
		top: 54px;
		background: #fff;
		border: 1px solid rgba(26,53,71,0.12);
		border-radius: 4px;
		box-shadow: 0 1px 2px rgba(26,53,71,0.1);
		opacity: 0;
		filter: alpha(opacity=0);
		-ms-filter: alpha(opacity=0);
		zoom: 1;
		pointer-events: none;
		transition: 0.25s cubic-bezier(0.3, 0, 0, 1.3);
		-webkit-transform: scale(.8) translateY(-30%);
		&::before {
			content: "";
			position: absolute;
			top: -7px;
			right: calc(50% - 9px);
			right: -webkit-calc(50% - 9px);
			right: -moz-calc(50% - 9px);
			width: 13px;
			height: 7px;
			z-index: 4;
			background: url(/public/imgs/icon/dropdown-menu-arrow.svg); }
		&.visible {
			display: block;
			opacity: 1;
			filter: alpha(opacity=100);
			-ms-filter: alpha(opacity=100);
			zoom: 1;
			pointer-events: auto;
			-webkit-transform: none;
			transform: none;
			z-index: 3;
			transition: all 1s; }
		.areas {
			max-height: 350px;
			overflow: scroll;
			li {
				display: block;
				width: 198px;
				padding: 0 19px;
				line-height: 40px;
				cursor: pointer;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-left: 25px;
				&:hover {
					background-color: #F5F5F5; } } } } }
