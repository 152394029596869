@charset "UTF-8";
$border_color: #F6F6F6;
$main_color: #FC4A1A;
$c_222: #222222;
$c_333: #333333;
$c_666: #666666;
$c_999: #999999;
$c_fff: #ffffff;
$c_bbb: #BBBBBB;

