@charset "UTF-8";
#web_privacy {
	color: #393839;
	word-break: break-word;
	padding: 50px 5% 5% 5%;
	min-height: 100vh;
	p {
		color: #393839;
		margin-top: 30px;
		color: #393839;
		font-size: 14px;
		line-height: 1.5;
		clear: both; }
	div {
		margin: 0;
		padding: 0;
		display: block; }
	h1 {
		border-bottom: none !important;
		font-size: 2em;
		line-height: 1.2em; }
	h2 {
		padding-left: 10px;
		border-left: 4px solid #FC4A1A;
		margin-bottom: 20px;
		font-size: 16px;
		color: #393839;
		line-height: 1.5;
		position: relative;
		font-weight: bold;
		margin-top: 30px; }
	h3 {
		font-size: 16px;
		color: #393839;
		line-height: 26px;
		margin-bottom: 20px;
		margin-top: 30px; }
	h4 {
		font-size: 16px;
		color: #393839;
		line-height: 26px;
		margin-top: 30px; }
	a {
		color: #FC4A1A;
		font-size: 14px;
		line-height: 1.5; }
	h5 {
		font-size: 0.83em; }
	h6 {
		font-size: 0.83em; }
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	b,
	strong {
		font-weight: bolder; }
	i,
	cite,
	em,
	var,
	address {
		font-style: italic; }
	pre,
	tt,
	code,
	kbd,
	samp {
		font-family: monospace; }
	pre {
		overflow: auto;
		background: #f5f5f5;
		padding: 8px;
		white-space: pre;
		margin: 1em 0px; }
	code {
		display: inline;
		background: #f5f5f5; }
	big {
		font-size: 1.17em; }
	small,
	sub,
	sup {
		font-size: 0.83em; }
	sub {
		vertical-align: sub; }
	sup {
		vertical-align: super; }
	s,
	strike,
	del {
		text-decoration: line-through; }
	strong,
	s {
		display: inline; }
	video {
		text-align: center;
		margin: 11px 0; }
	video-video {
		width: 100%; }
	img {
		display: block;
		max-width: 100%;
		margin-bottom: -1em;
		overflow: hidden; }
	blockquote {
		margin: 5px 0;
		padding: 11px 0 11px 11px;
		font-family: Courier, Calibri, "宋体";
		background: #f5f5f5;
		border-left: 3px solid #dbdbdb; }
	blockquote {
		p {
			margin: 0; } }
	ul, ol {
		display: block;
		margin: 1em 0;
		padding-left: 2em; }
	ol {
		list-style-type: disc; }
	ol {
		list-style-type: decimal; }
	ol>weixin-parse-template,ul>weixin-parse-template {
		display: list-item;
		align-items: baseline;
		text-align: match-parent; }
	ol>li,ul>li {
		display: list-item;
		align-items: baseline;
		text-align: match-parent;
		font-size: 14px;
		color: #393839;
		line-height: 1.8; }
	ul>li {
		list-style: circle; } }
