@charset "UTF-8";
//页面安全距离
$safe_size: 15px;
// 弹性布局 垂直居中。两端对齐
@mixin flex_sc() {
	display: flex;
	justify-content: space-between;
	align-items: center; }
// 弹性布局 垂直水平居中
@mixin flex_cc() {
	display: flex;
	justify-content: center;
	align-items: center; }
// 隐藏滚动条
@mixin hideScrollLine() {
	&::-webkit-scrollbar {
		display: none; } }
// 颜色、字号、行高
@mixin font($color,$fontSise,$lineHeight) {
	color: $color;
	font-size: $fontSise+px;
	line-height: $lineHeight+px; }
//省略一行
@mixin text_1() {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden; }
//省略二行
@mixin text_2() {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden; }
//省略三行
@mixin text_3() {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden; }
//省略四行
@mixin text_4() {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden; }
//计算宽度
@mixin width($size,$sizeB) {
	width: ($size/$sizeB)*100%; }
// 基础尺寸
$vm_fontsize: 75; // iPhone 6尺寸的根元素大小基准值
@function rem($px) {
	@return ($px / $vm_fontsize ) * 1rem; }
