@charset "UTF-8";
body#app_index {
	background: #fff;
	margin: 0 auto;
	section.apps_download {
		width: 100%;
		// height: 100vh
		max-width: 1440px;
		@include flex_cc();
		flex-direction: column;
		background: url(/public/imgs/apps/pc/bg-1.png);
		background-size: 100% 100%;
		margin: 0 auto;
		.box_1 {
			@include width(1200,1440);
			height: 100%;
			margin: 78px auto 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.imgs {
				img {
					width: 100%; } }
			.content-1 {
				width: 376px;
				margin-bottom: 130px; }
			.content-2 {
				width: 547px; }
			.tips {
				@include font(#000,26,37); }
			.app_item {
				@include flex_sc();
				margin-top: 30px;
				.content-qcode {
					width: 240px; }
				.app_btn {
					@include flex_sc();
					flex-direction: column;
					margin-top: rem(33);
					margin-bottom: rem(64);
					justify-content: space-around;
					>div {
						width: 243px; } } }
			.tips_2 {
				@include font(#000,18,20);
				a {
					color: #68BAAC; } } }
		.item_right {
			.login_box {
				display: flex;
				justify-content: flex-end;
				position: relative;
				.username {
					@include flex_cc();
					@include font(#666666,16,14);
					cursor: pointer;
					img {
						width: 20px;
						height: 20px;
						min-width: 20px;
						margin-right: 10px;
						border-radius: 50%; } }
				.unlogin_btn {
					width: 143px;
					height: 74px;
					background: url(/public/imgs/common/icon_unlogin.png);
					background-size: 100% 100%;
					position: absolute;
					top: 45px;
					right: 0;
					z-index: 100; }
				.to_upload {
					@include font(#666666,16,14);
					cursor: pointer;
					margin-right: 50px;
					@include flex_cc();
					&:before {
						content: '';
						display: inline-block;
						width: 25px;
						height: 14px;
						margin-right: 10px;
						min-width: 25px;
						background: url(/public/imgs/common/icon_upload.png);
						background-size: 100% 100%; } } }
			.unlogin_box {
				display: flex;
				justify-content: flex-end;
				.to_upload {
					@include font(#666666,16,14);
					cursor: pointer;
					margin-right: 50px;
					@include flex_cc();
					&:before {
						content: '';
						display: inline-block;
						width: 25px;
						height: 14px;
						margin-right: 10px;
						min-width: 25px;
						background: url(/public/imgs/common/icon_upload.png);
						background-size: 100% 100%; } }
				.to_login {
					@include font(#666666,16,14);
					cursor: pointer;
					@include flex_cc();
					&:before {
						content: '';
						display: inline-block;
						width: 20px;
						height: 20px;
						margin-right: 10px;
						min-width: 20px;
						background: url(/public/imgs/common/icon_login_grey.png);
						background-size: 100% 100%; } } } } }
	.hide {
		display: none !important; } }


