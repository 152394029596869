.account_section {
	@include flex_cc();
	min-height: 800px;
	width: 100%;
	background: #ccc;
	min-height: 900px;
	height: 100vh;
	.logo_box {
		width: 370px;
		height: 460px;
		background: url(/public/imgs/login/bg_dsp.png);
		background-size: 100% 100%;
		@include flex_cc();
		img {
			width: 210px; } }
	.password_box,.register_box,.login_box {
		@include flex_cc(); }
	.hide {
		display: none !important; } }
// 登录
.login_box,.register_box,.password_box {
	position: relative;
	.acc_log,.code_log,.reg_log,.pas_log {
		background: #fff;
		border-radius: 0 10px 10px 0;
		height: 100%;
		min-height: 460px;
		width: 500px;
		.tit_mode {
			@include flex_sc();
			align-items: flex-end;
			padding: 50px 45px 20px;
			border-bottom: 2px solid $main_color;
			.tit_left {
				display: flex;
				align-items: flex-end;
				@include font(#3D3D3D,16,30);
				.large {
					@include font(#3D3D3D,24,37); }
				>span {
					@include font($main_color,16,30); }
				>i {
					margin: 0 5px;
					font-style: inherit;
					display: inline-block; } }
			.tit_right {
				@include font(#3D3D3D,16,30);
				>span {
					@include font($main_color,16,30); } } }
		.btn_login {
			margin: 0 auto;
			width: 400px;
			height: 38px;
			background: $main_color;
			border-radius: 5px;
			@include font(#FFFFFF,20,30);
			@include flex_cc();
			letter-spacing: 20px;
			&.btn_grey {
				color: #3D3D3D;
				background: #DCDCDC; } }
		.account_part {
			padding: 45px;
			.username_mode {
				.err_tips {
					@include font($main_color,13,30); } }
			input[type=text],input[type=password] {
				width: 100%;
				border: none;
				padding: 0 5px 5px;
				@include font(#3D3D3D,18,30);
				border: 0;
				outline: none;
				border-bottom: 1px solid #DCDCDC;
				-webkit-tap-highlight-color: rgba(255,0,0,0);
				box-shadow: 0 0 0 60px #fff inset;
				&::-webkit-input-placeholder {
					color: #ABABAB; } }
			input[name=password],input[name=new_password_repeat] {
				margin-top: 20px; }
			.other {
				@include flex_sc();
				margin-top: 1;
				margin-top: 10px;
				.to_remember {
					display: flex;
					align-items: center;
					>input[name=checkbox] {
						flex-shrink: 0; }
					>span {
						flex-shrink: 0;
						@include font(#3D3D3D,13,30); } }
				.to_password {
					@include font(#3D3D3D,13,30); } } } }

	.code_log,.reg_log,.pas_log {
		.phone_mode {
			display: flex;
			width: 100%;
			border-bottom: 1px solid #DCDCDC;
			.sel_area {
				width: 20%;
				.choise {
					@include flex_cc();
					.show_code {
						@include font(#3D3D3D,20,30);
						display: flex;
						align-items: center; }
					.open_menu {
						width: 13px;
						min-width: 13px;
						height: 8px;
						background: url(/public/imgs/login/icon_up.png);
						background-size: 100% 100%;
						margin-left: 10px;
						margin-right: 10px; } } }
			.username_mode {
				display: flex;
				flex-direction: column;
				width: 100%;
				input {
					border: none; } } }
		.code_mode {
			position: relative;
			margin-top: 20px;
			.js_send_code {
				position: absolute;
				right: 0;
				@include font(#3D3D3D,14,20);
				border: 1px solid #ABABAB;
				border-radius: 5px;
				padding: 2px 10px; } }
		.tips {
			@include font(#ABABAB,13,30);
			margin-top: 48px;
			text-align: center; }
		.btn_login {
			margin-top: 40px; } } }

.login_box {
	.acc_log {
		.account_part {
			padding-bottom: 45px; } } }
// 注册
.register_box {
	.reg_log {
		.account_part {
			padding-bottom: 28px; }
		.tips {
			margin-top: 46px;
			a {
				color: $main_color; } }
		.btn_login {
			margin-top: 0px; } } }

// 忘记密码
.password_box {
	.pas_log {
		.account_part {
			padding-top: 40px;
			padding-bottom: 20px; }
		.btn_login {
			margin-top: 0px; } } }
